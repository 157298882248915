@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://use.typekit.net/fsr1bmj.css");

/*!* HACK: this is to get rid of a dumb issue in dev*!*/
/*body > iframe[style*="2147483647"]:not([id="webpack-dev-server-client-overlay"]) {*/
/*    display: none;*/
/*}*/

body {
  margin: 0;
  font-family: din-2014, sans-serif;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f2f2f2 !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

code {
  font-family: din-2014, sans-serif;
}
.tags-input-container {
  margin-top: 8px;
}
.tags-input {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  min-height: 48px;
  width: 480px;
  padding: 0 8px;
  border: 1px solid #d6d8da;
  border-radius: 6px;
}
.tags-input:focus-within {
  border: 1px solid #0052cc;
}
.tags-input input {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border: none;
  height: 46px;
  font-size: 14px;
  padding: 4px 0 0 0;
}
.tags-input input:focus {
  outline: transparent;
}

#tags {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0;
  margin: 8px 0 0 0;
}

.tag {
  width: auto;
  height: 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #fff;
  padding: 0 8px;
  font-size: 14px;
  list-style: none;
  border-radius: 6px;
  margin: 0 8px 8px 0;
  background: #0052cc;
}
.tag .tag-title {
  margin-top: 3px;
}
.tag .tag-close-icon {
  display: block;
  width: 16px;
  height: 16px;
  line-height: 16px;
  text-align: center;
  font-size: 14px;
  margin-left: 8px;
  color: #0052cc;
  border-radius: 50%;
  background: #fff;
  cursor: pointer;
}

.nexstar-tooltip {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
  font-size: 1rem !important;
  max-width: 375px;
  opacity: 1 !important;
  z-index: 99999;
}

@media screen and (max-width: 567px) {
  .tags-input {
    width: calc(100vw - 32px);
  }
}

@media screen and (max-width: 767px) {
  #profitpricer_progress {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  #profitpricer_progress > a {
    margin-bottom: 1rem;
  }
  .MuiTabs-fixed {
    overflow-x: scroll !important;
  }
}

a {
  color: #203b6c;
  -webkit-transition: 0.25s;
  -o-transition: 0.25s;
  transition: 0.25s;
}

a:hover {
  text-decoration: none;
  color: #e39022;
}

@layer utilities {
  .animation-delay-150 {
    animation-delay: 150ms;
  }
  .animation-delay-200 {
    animation-delay: 200ms;
  }
  .animation-delay-300 {
    animation-delay: 300ms;
  }
  .animation-delay-400 {
    animation-delay: 400ms;
  }
  .animation-delay-450 {
    animation-delay: 450ms;
  }
  .animation-delay-600 {
    animation-delay: 600ms;
  }
  .animation-delay-750 {
    animation-delay: 750ms;
  }
  .animation-delay-900 {
    animation-delay: 900ms;
  }
  .animation-delay-1050 {
    animation-delay: 1050ms;
  }
}

div.ag-theme-alpine div.ag-row {
  font-family: din-2014, sans-serif;
}
.ag-theme-alpine .ag-header {
  font-weight: 700;
  font-family: din-2014, sans-serif;
}

.am5exporting {
  font-size: 1.5rem;


}
a.am5exporting-icon,
.am5exporting-list{
  background: #e39022;
  opacity: 1;
  width: fit-content;
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

a.am5exporting-icon:hover,
.am5exporting-menu-open a.am5exporting-icon,
.am5exporting-item a:hover {
  background: #ffc055;
}
a.am5exporting-icon > svg
{

  transform: scale(2);
}



